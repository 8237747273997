import React from "react"
import Link from "./link";
import { domain } from '../utils';
import { graphql } from 'gatsby';
import MouseHover from "./mouseHover";
import Tags from "./tags";
import classNames from "classnames";

const CardArticle = ({ article, path ,showMobileImage, shouldNotLazyLoad, isCasesPage, media, isCaseDetailPage, imageBorderRadiusForServices, hashtagsBackgroundColor}) => {
  if (isCaseDetailPage) {
    return (
      <article
        className="card-article col"
        role="listitem"
      >
        <img 
          style={{borderRadius: imageBorderRadiusForServices}}       
          src={`${media?.url}`}  
          className="image--fluid" 
          {...(!shouldNotLazyLoad && { loading: "lazy" })} 
        />
      </article>
    )
  }
  if (!article) { return <article></article>; }
  
  return (
    <article
      className="card-article col-sm-4"
      role="listitem"
    >
      <Link
        className="card-article__wrap"
        to={`/${article.language}/${path}/${article.slug}`}
        title={article.title}
      >
       <MouseHover 
         classId={`card-article__image nocursor ${article.tags && article.tags.length > 0 ? "mb-0" : ""}`} 
         borderRadius={imageBorderRadiusForServices}
       >
        {showMobileImage ? (
          <img 
            style={{borderRadius: imageBorderRadiusForServices}}       
            src={`${article.headerImageMobile && article.headerImageMobile.url}`} 
            alt={article.title} 
            className="image--fluid" {...(!shouldNotLazyLoad && { loading: "lazy" })} 
          />
        ) : (
          <img
            style={{borderRadius: imageBorderRadiusForServices}}       
            className = "image--fluid"  
            src = {`${isCasesPage  ? (article.previewImage && article.previewImage.url ? article.previewImage && article.previewImage.url : article.headerImage && article.headerImage.url  ) : article.headerImage && article.headerImage.url }`}
            alt="placeholder"
            {...(!shouldNotLazyLoad && { loading: 'lazy' })}   
          />
          )}
       </MouseHover>
        <Tags 
          tags={article.tags.filter((tag) => tag?.active)} 
          hashtagsBackgroundColor={hashtagsBackgroundColor} 
          borderRadius={imageBorderRadiusForServices}
        />
        <h2 className={classNames("card-article__header", {"mt-0": article.tags && article.tags.length})}>          
          {article.title}
        </h2>
      </Link>
    </article>
  )
}

export const query = graphql`
  fragment CardArticleBlog on STRAPI_Blog {
    language
    slug
    inactive
    headerImage {
      url
    }
    headerImageMobile {
      url
    }
    title
    intro
    tags {
      tag
      active
    }
    date
  }
  fragment CardArticleCaseStudy on STRAPI_CaseStudy {
    clientLogo {
      logo {
        url 
      }
    }
    language
    slug
    inactive
    headerImage {
      url
    }
    decriptionDisplayText
    headerImageMobile{
      url
    }
    showCtaButton
    previewImage {
      url
    }
    title
    description
    tags {
      tag
      active
    }
    date
  }
`

export default CardArticle